<template>
  <div>
    <div class="topic_box">
      <div :id="type == 2 ? data.isNum + 1 : is_num">
        <div class="question_title" v-show="data.qu_metatype == 1">
          {{ type == 2 ? data.isNum + 1 : is_num }}. {{ data.qu_content }}
        </div>
        <div
          class="question_title"
          v-show="data.qu_metatype == 2"
          style="display: flex"
        >
          {{ type == 2 ? data.isNum + 1 : is_num }}.
          <p style="max-width: 1050px" v-html="data.qu_content"></p>
        </div>
        <div v-show="data.qu_metatype == 3">
          {{ type == 2 ? data.isNum + 1 : is_num }}.&nbsp;
          <img style="max-width: 1050px" :src="data.qu_content" alt="" />
        </div>
      </div>
    </div>
    <!-- 多选问题选项 -->
    <div class="radio_box">
      <ul>
        <!-- A -->
        <li v-show="data.an_item_a_metatype && data.an_item_a_metatype > 0">
          <div class="left" @click="clickQulist('A')">
            <!-- 回显状态 -->
            <button
              :class="
                type == 2
                  ? paper[data.isNum + 1] &&
                    paper[data.isNum + 1].indexOf('A') > -1
                    ? 'd_x_x_z'
                    : ''
                  : data.user_answer.indexOf('A') > -1 &&
                    data.answer.indexOf('A') < 0
                  ? 'wrong'
                  : data.answer.indexOf('A') > -1 && data.user_answer != ''
                  ? 'yes'
                  : data.check_all && data.check_all.indexOf('A') > -1 && data.user_answer == ''
                  ? 'd_x_x_z'
                  : ''
              "
            >
              A
            </button>
            <p v-if="data.an_item_a_metatype == 1">
              {{ data.an_item_a_content }}
            </p>
            <p
              v-if="data.an_item_a_metatype == 2"
              v-html="data.an_item_a_content"
            ></p>
            <img
              v-show="data.an_item_a_metatype == 3"
              :src="data.an_item_a_content"
              alt=""
            />
          </div>
          <a
            class="right"
            :href="data.an_item_a_content"
            target="_blank"
            v-show="data.an_item_a_metatype == 3"
          >
            <img src="../../assets/img/yulan.png" alt="" />
            大图预览
          </a>
        </li>
        <!-- B -->
        <li v-show="data.an_item_b_metatype && data.an_item_b_metatype > 0">
          <div class="left" @click="clickQulist('B')">
            <!-- 回显状态 -->
            <button
              :class="
                type == 2
                  ? paper[data.isNum + 1] &&
                    paper[data.isNum + 1].indexOf('B') > -1
                    ? 'd_x_x_z'
                    : ''
                  : data.user_answer.indexOf('B') > -1 &&
                    data.answer.indexOf('B') < 0
                  ? 'wrong'
                  : data.answer.indexOf('B') > -1 && data.user_answer != ''
                  ? 'yes'
                  : data.check_all && data.check_all.indexOf('B') > -1
                  ? 'd_x_x_z'
                  : ''
              "
            >
              B
            </button>
            <p v-if="data.an_item_b_metatype == 1">
              {{ data.an_item_b_content }}
            </p>
            <p
              v-if="data.an_item_b_metatype == 2"
              v-html="data.an_item_b_content"
            ></p>
            <img
              v-show="data.an_item_b_metatype == 3"
              :src="data.an_item_b_content"
              alt=""
            />
          </div>
          <a
            class="right"
            :href="data.an_item_b_content"
            target="_blank"
            v-show="data.an_item_b_metatype == 3"
          >
            <img src="../../assets/img/yulan.png" alt="" />
            大图预览
          </a>
        </li>
        <!-- C -->
        <li v-show="data.an_item_c_metatype && data.an_item_c_metatype > 0">
          <div class="left" @click="clickQulist('C')">
            <!-- 回显状态 -->
            <button
              :class="
                type == 2
                  ? paper[data.isNum + 1] &&
                    paper[data.isNum + 1].indexOf('C') > -1
                    ? 'd_x_x_z'
                    : ''
                  : data.user_answer.indexOf('C') > -1 &&
                    data.answer.indexOf('C') < 0
                  ? 'wrong'
                  : data.answer.indexOf('C') > -1 && data.user_answer != ''
                  ? 'yes'
                  : data.check_all && data.check_all.indexOf('C') > -1
                  ? 'd_x_x_z'
                  : ''
              "
            >
              C
            </button>
            <p v-if="data.an_item_c_metatype == 1">
              {{ data.an_item_c_content }}
            </p>
            <p
              v-if="data.an_item_c_metatype == 2"
              v-html="data.an_item_c_content"
            ></p>
            <img
              v-show="data.an_item_c_metatype == 3"
              :src="data.an_item_c_content"
              alt=""
            />
          </div>
          <a
            class="right"
            :href="data.an_item_c_content"
            target="_blank"
            v-show="data.an_item_c_metatype == 3"
          >
            <img src="../../assets/img/yulan.png" alt="" />
            大图预览
          </a>
        </li>
        <!-- D -->
        <li v-show="data.an_item_d_metatype && data.an_item_d_metatype > 0">
          <div class="left" @click="clickQulist('D')">
            <!-- 回显状态 -->
            <button
              :class="
                type == 2
                  ? paper[data.isNum + 1] &&
                    paper[data.isNum + 1].indexOf('D') > -1
                    ? 'd_x_x_z'
                    : ''
                  : data.user_answer.indexOf('D') > -1 &&
                    data.answer.indexOf('D') < 0
                  ? 'wrong'
                  : data.answer.indexOf('D') > -1 && data.user_answer != ''
                  ? 'yes'
                  : data.check_all && data.check_all.indexOf('D') > -1
                  ? 'd_x_x_z'
                  : ''
              "
            >
              D
            </button>
            <p v-if="data.an_item_d_metatype == 1">
              {{ data.an_item_d_content }}
            </p>
            <p
              v-if="data.an_item_d_metatype == 2"
              v-html="data.an_item_d_content"
            ></p>
            <img
              v-show="data.an_item_d_metatype == 3"
              :src="data.an_item_d_content"
              alt=""
            />
          </div>
          <a
            class="right"
            :href="data.an_item_d_content"
            target="_blank"
            v-show="data.an_item_d_metatype == 3"
          >
            <img src="../../assets/img/yulan.png" alt="" />
            大图预览
          </a>
        </li>
        <!-- E -->
        <li v-show="data.an_item_e_metatype && data.an_item_e_metatype > 0">
          <div class="left" @click="clickQulist('E')">
            <!-- 回显状态 -->
            <button
              :class="
                type == 2
                  ? paper[data.isNum + 1] &&
                    paper[data.isNum + 1].indexOf('E') > -1
                    ? 'd_x_x_z'
                    : ''
                  : data.user_answer.indexOf('E') > -1 &&
                    data.answer.indexOf('E') < 0
                  ? 'wrong'
                  : data.answer.indexOf('E') > -1 && data.user_answer != ''
                  ? 'yes'
                  : data.check_all && data.check_all.indexOf('E') > -1
                  ? 'd_x_x_z'
                  : ''
              "
            >
              E
            </button>
            <p v-if="data.an_item_e_metatype == 1">
              {{ data.an_item_e_content }}
            </p>
            <p
              v-if="data.an_item_e_metatype == 2"
              v-html="data.an_item_e_content"
            ></p>
            <img
              v-show="data.an_item_e_metatype == 3"
              :src="data.an_item_e_content"
              alt=""
            />
          </div>
          <a
            class="right"
            :href="data.an_item_e_content"
            target="_blank"
            v-show="data.an_item_e_metatype == 3"
          >
            <img src="../../assets/img/yulan.png" alt="" />
            大图预览
          </a>
        </li>
        <!-- F -->
        <li v-show="data.an_item_f_metatype && data.an_item_f_metatype > 0">
          <div class="left" @click="clickQulist('F')">
            <!-- 回显状态 -->
            <button
              :class="
                type == 2
                  ? paper[data.isNum + 1] &&
                    paper[data.isNum + 1].indexOf('F') > -1
                    ? 'd_x_x_z'
                    : ''
                  : data.user_answer.indexOf('F') > -1 &&
                    data.answer.indexOf('F') < 0
                  ? 'wrong'
                  : data.answer.indexOf('F') > -1 && data.user_answer != ''
                  ? 'yes'
                  :data.check_all && data.check_all.indexOf('F') > -1
                  ? 'd_x_x_z'
                  : ''
              "
            >
              F
            </button>
            <p v-if="data.an_item_f_metatype == 1">
              {{ data.an_item_f_content }}
            </p>
            <p
              v-if="data.an_item_f_metatype == 2"
              v-html="data.an_item_f_content"
            ></p>
            <img
              v-show="data.an_item_f_metatype == 3"
              :src="data.an_item_f_content"
              alt=""
            />
          </div>
          <a
            class="right"
            :href="data.an_item_f_content"
            target="_blank"
            v-show="data.an_item_f_metatype == 3"
          >
            <img src="../../assets/img/yulan.png" alt="" />
            大图预览
          </a>
        </li>
      </ul>
      <el-button
        class="YES"
        type="primary"
        v-show="data.user_answer == '' && type != 2"
        @click="check_click"
        >确定</el-button
      >
    </div>
    <!-- 答案解析------------------------------------------------- -->
    <div class="analysis_box" v-if="type != 2">
      <div class="top" v-show="data.user_answer != ''">
        <div class="left">
          <span>正确答案: {{ data.answer }}</span>
          <span v-show="data.answer == data.user_answer">
            您的答案:&nbsp;
            <p style="margin-right: 20px">
              {{ data.user_answer.toString() }}
            </p>
            <img
              class="dui_icon"
              src="../../assets/img/dui_answer.png"
              alt=""
            />
          </span>
          <span class="wrong_answer" v-show="data.answer != data.user_answer">
            您的答案:&nbsp;
            <p style="margin-right: 20px">
              {{ data.user_answer.toString() }}
            </p>
            <img src="../../assets/img/wrong.png" alt="" />
          </span>
        </div>
      </div>
      <div class="down" v-show="!isReveal && data.user_answer != ''">
        <div class="down_content">
          <!-- 答案解析的元数据类型，1纯文字，2富文本(HTML)，3纯图片 -->
          <div>答案解析:</div>
          <div v-if="data.an_detail_metatype == 1">
            {{ data.an_detail_content }}
          </div>
          <div
            style="white-space: pre-wrap; word-break: break-word"
            v-html="data.an_detail_content"
            v-else-if="data.an_detail_metatype == 2"
          ></div>
          <img :src="data.an_detail_metatype" alt="" v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Question_4",
  props: {
    is_num: Number, //对应题数
    data: Object, //问题数据
    type: Number, //1答题   2 试卷
    isReveal: Boolean, //答案解析状态
  },
  data() {
    return {
      check_all: [],
      paper: [], //答案
    };
  },
  computed: {},
  created() {
    //   console.log(this.data)
    if (this.type == 1) {
      this.check_all = this.data.user_answer.split(",");
    }
    if (this.type == 2) {
      this.paper = this.$store.state.paper;
    }
  },
  methods: {
    //选择
    clickQulist(val) {
      switch (this.type) {
        case 1:
          if (this.data.user_answer == "") {
            if (!this.data.check_all) {
              this.data.check_all = [];
              this.data.check_all.push(val);
            } else {
              let index = this.data.check_all.indexOf(val);
              if (index > -1) {
                this.data.check_all.splice(index, 1);
              } else {
                this.data.check_all.push(val);
              }
            }
            this.$forceUpdate();
          }
          break;
        case 2:
          let paper = this.paper;
          let a = paper[this.data.isNum + 1];
          //   console.log(a);
          if (a) {
            // console.log(a.split(","));
            a = a.split(",");
            // console.log(a.indexOf(val))
            if (a.indexOf(val) > -1) {
              a.splice(a.indexOf(val), 1);
              //   console.log(a)
              paper[this.data.isNum + 1] = a.toString();
            } else {
              a = [...a, ...val].sort();
              // console.log(a)
              paper[this.data.isNum + 1] = a.toString();
            }
          } else {
            a = [];
            a.push(val);
            paper[this.data.isNum + 1] = a.toString();
            // console.log(a)
          }

          this.$store.commit("PaperState", paper);
          this.paper = paper;
          this.$emit("isTNum", this.data.isNum + 1);
          this.$forceUpdate();
          break;
      }
    },
    //多选确认
    check_click() {
      let b = this.data.check_all.filter((e) => {
        return e != "";
      });
      this.data.user_answer = b.sort().toString();
      if (this.data.user_answer == this.data.answer) {
        this.data.is_YN = 1;
      } else {
        this.data.is_YN = 2;
      }
      this.check_all = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.d_x_x_z {
  background: #3e7eff !important;
  color: #fff !important;
  border: none !important;
}
</style>
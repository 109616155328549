<template>
  <div style="padding-bottom: 70px">
    <!-- 题目 / qu_metatype 1纯文字 2富文本 3图片链接-->
    <!-- 材料题题目选项 -->
    <div>
      <!-- 材料题 -->
      <div>
        <div class="question_title" v-show="data.case_metatype == 1">
          {{ type == 2 ? data.qu_list[0].isNum + 1 : is_num }}.
          {{ data.case_content }}
        </div>
        <div
          class="question_title"
          v-show="data.case_metatype == 2"
          style="display: flex; flex-wrap: wrap"
        >
          {{ type == 2 ? data.qu_list[0].isNum + 1 : is_num }}.
          <p style="max-width: 1050px" v-html="data.case_content"></p>
        </div>
        <div v-show="data.case_metatype == 3">
          {{ type == 2 ? data.qu_list[0].isNum + 1 : is_num }}.&nbsp;
          <img style="max-width: 1050px" :src="data.case_content" alt="" />
        </div>
      </div>
      <div
        class="radio_box_pcl"
        v-for="(c, i) in data.qu_list"
        :key="i"
        :id="type == 2 ? c.isNum + 1 : is_num"
      >
        <div v-if="c.qu_metatype == 1">
          {{ type == 2 ? c.isNum + 1 : (i + 1) }}.
          {{ c.qu_content }}
        </div>
        <div style="display: flex" v-if="c.qu_metatype == 2">
          {{ type == 2 ? c.isNum + 1 : (i + 1) }}.
          <p style="max-width: 1050px" v-html="c.qu_content"></p>
        </div>
        <div style="display: flex" v-if="c.qu_metatype == 3">
          {{ type == 2 ? c.isNum + 1 : (i + 1) }}.
          <img style="max-width: 1050px" :src="c.qu_content" alt="" />
        </div>
        <ul style="margin-top: 30px">
          <!-- A -->
          <li v-show="c.an_item_a_metatype && c.an_item_a_metatype > 0">
            <div class="left" @click="pw_click('A', i)">
              <!-- 回显状态 -->
              <button
                :class="
                  type == 2
                    ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('A') > -1
                      ? 'd_x_x_z'
                      : ''
                    : c.is_y
                    ? c.user_answer.indexOf('A') > -1 &&
                      c.answer.indexOf('A') < 0
                      ? 'wrong'
                      : c.answer.indexOf('A') > -1
                      ? 'yes'
                      : ''
                    : c.user_answer.indexOf('A') > -1
                    ? 'd_x_x_z'
                    : ''
                "
              >
                A
              </button>
              <p v-if="c.an_item_a_metatype == 1">
                {{ c.an_item_a_content }}
              </p>
              <p
                v-if="c.an_item_a_metatype == 2"
                v-html="c.an_item_a_content"
              ></p>
              <img
                v-show="c.an_item_a_metatype == 3"
                :src="c.an_item_a_content"
                alt=""
              />
            </div>
            <a
              class="right"
              :href="c.an_item_a_content"
              target="_blank"
              v-show="c.an_item_a_metatype == 3"
            >
              <img src="../../assets/img/yulan.png" alt="" />
              大图预览
            </a>
          </li>
          <!-- B -->
          <li v-show="c.an_item_b_metatype && c.an_item_b_metatype > 0">
            <div class="left" @click="pw_click('B', i)">
              <!-- 回显状态 -->
              <button
                :class="
                  type == 2
                    ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('B') > -1
                      ? 'd_x_x_z'
                      : ''
                    : c.is_y
                    ? c.user_answer.indexOf('B') > -1 &&
                      c.answer.indexOf('B') < 0
                      ? 'wrong'
                      : c.answer.indexOf('B') > -1
                      ? 'yes'
                      : ''
                    : c.user_answer.indexOf('B') > -1
                    ? 'd_x_x_z'
                    : ''
                "
              >
                B
              </button>
              <p v-if="c.an_item_b_metatype == 1">
                {{ c.an_item_b_content }}
              </p>
              <p
                v-if="c.an_item_b_metatype == 2"
                v-html="c.an_item_b_content"
              ></p>
              <img
                v-show="c.an_item_b_metatype == 3"
                :src="c.an_item_b_content"
                alt=""
              />
            </div>
            <a
              class="right"
              :href="c.an_item_b_content"
              target="_blank"
              v-show="c.an_item_b_metatype == 3"
            >
              <img src="../../assets/img/yulan.png" alt="" />
              大图预览
            </a>
          </li>
          <!-- C -->
          <li v-show="c.an_item_c_metatype && c.an_item_c_metatype > 0">
            <div class="left" @click="pw_click('C', i)">
              <!-- 回显状态 -->
              <button
                :class="
                  type == 2
                    ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('C') > -1
                      ? 'd_x_x_z'
                      : ''
                    : c.is_y
                    ? c.user_answer.indexOf('C') > -1 &&
                      c.answer.indexOf('C') < 0
                      ? 'wrong'
                      : c.answer.indexOf('C') > -1
                      ? 'yes'
                      : ''
                    : c.user_answer.indexOf('C') > -1
                    ? 'd_x_x_z'
                    : ''
                "
              >
                C
              </button>
              <p v-if="c.an_item_c_metatype == 1">
                {{ c.an_item_c_content }}
              </p>
              <p
                v-if="c.an_item_c_metatype == 2"
                v-html="c.an_item_c_content"
              ></p>
              <img
                v-show="c.an_item_c_metatype == 3"
                :src="c.an_item_c_content"
                alt=""
              />
            </div>
            <a
              class="right"
              :href="c.an_item_c_content"
              target="_blank"
              v-show="c.an_item_c_metatype == 3"
            >
              <img src="../../assets/img/yulan.png" alt="" />
              大图预览
            </a>
          </li>
          <!-- D -->
          <li v-show="c.an_item_d_metatype && c.an_item_d_metatype > 0">
            <div class="left" @click="pw_click('D', i)">
              <!-- 回显状态 -->
              <button
                :class="
                  type == 2
                    ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('D') > -1
                      ? 'd_x_x_z'
                      : ''
                    : c.is_y
                    ? c.user_answer.indexOf('D') > -1 &&
                      c.answer.indexOf('D') < 0
                      ? 'wrong'
                      : c.answer.indexOf('D') > -1
                      ? 'yes'
                      : ''
                    : c.user_answer.indexOf('D') > -1
                    ? 'd_x_x_z'
                    : ''
                "
              >
                D
              </button>
              <p v-if="c.an_item_d_metatype == 1">
                {{ c.an_item_d_content }}
              </p>
              <p
                v-if="c.an_item_d_metatype == 2"
                v-html="c.an_item_d_content"
              ></p>
              <img
                v-show="c.an_item_d_metatype == 3"
                :src="c.an_item_d_content"
                alt=""
              />
            </div>
            <a
              class="right"
              :href="c.an_item_d_content"
              target="_blank"
              v-show="c.an_item_d_metatype == 3"
            >
              <img src="../../assets/img/yulan.png" alt="" />
              大图预览
            </a>
          </li>
          <!-- E -->
          <li v-show="c.an_item_e_metatype && c.an_item_e_metatype > 0">
            <div class="left" @click="pw_click('E', i)">
              <!-- 回显状态 -->
              <button
                :class="
                  type == 2
                    ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('E') > -1
                      ? 'd_x_x_z'
                      : ''
                    : c.is_y
                    ? c.user_answer.indexOf('E') > -1 &&
                      c.answer.indexOf('E') < 0
                      ? 'wrong'
                      : c.answer.indexOf('E') > -1
                      ? 'yes'
                      : ''
                    : c.user_answer.indexOf('E') > -1
                    ? 'd_x_x_z'
                    : ''
                "
              >
                E
              </button>
              <p v-if="c.an_item_e_metatype == 1">
                {{ c.an_item_e_content }}
              </p>
              <p
                v-if="c.an_item_e_metatype == 2"
                v-html="c.an_item_e_content"
              ></p>
              <img
                v-show="c.an_item_e_metatype == 3"
                :src="c.an_item_e_content"
                alt=""
              />
            </div>
            <a
              class="right"
              :href="c.an_item_e_content"
              target="_blank"
              v-show="c.an_item_e_metatype == 3"
            >
              <img src="../../assets/img/yulan.png" alt="" />
              大图预览
            </a>
          </li>
          <!-- F -->
          <li v-show="c.an_item_f_metatype && c.an_item_f_metatype > 0">
            <div class="left" @click="pw_click('F', i)">
              <!-- 回显状态 -->
              <button
                :class="
                  type == 2
                    ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('F') > -1
                      ? 'd_x_x_z'
                      : ''
                    : c.is_y
                    ? c.user_answer.indexOf('F') > -1 &&
                      c.answer.indexOf('F') < 0
                      ? 'wrong'
                      : c.answer.indexOf('F') > -1
                      ? 'yes'
                      : ''
                    : c.user_answer.indexOf('F') > -1
                    ? 'd_x_x_z'
                    : ''
                "
              >
                F
              </button>
              <p v-if="c.an_item_f_metatype == 1">
                {{ c.an_item_f_content }}
              </p>
              <p
                v-if="c.an_item_f_metatype == 2"
                v-html="c.an_item_f_content"
              ></p>
              <img
                v-show="c.an_item_f_metatype == 3"
                :src="c.an_item_f_content"
                alt=""
              />
            </div>
            <a
              class="right"
              :href="c.an_item_f_content"
              target="_blank"
              v-show="c.an_item_f_metatype == 3"
            >
              <img src="../../assets/img/yulan.png" alt="" />
              大图预览
            </a>
          </li>
        </ul>
        <el-button
          v-show="c.option_type == 4 && !c.is_y && type != 2"
          type="primary"
          class="YES"
          @click="check_click(i)"
          >确定</el-button
        >
        <div
          class="analysis_box"
          style="margin: 30px 0 0"
          v-if="c.is_y && type != 2"
        >
          <div class="top">
            <div class="left">
              <span>正确答案: {{ c.answer }}</span>
              <span v-show="c.answer == c.user_answer && c.is_y">
                您的答案:&nbsp;
                <p style="margin-right: 20px">
                  {{ c.user_answer }}
                </p>
                <img
                  class="dui_icon"
                  src="../../assets/img/dui_answer.png"
                  alt=""
                />
              </span>
              <span
                class="wrong_answer"
                v-show="c.answer != c.user_answer || c.user_answer == ''"
              >
                您的答案:&nbsp;
                <p style="margin-right: 20px">
                  {{ c.user_answer }}
                </p>
                <img src="../../assets/img/wrong.png" alt="" />
              </span>
            </div>
          </div>
          <div class="down" v-show="!isReveal">
            <div class="down_content">
              <!-- 答案解析的元数据类型，1纯文字，2富文本(HTML)，3纯图片 -->
              <div>答案解析:</div>
              <div v-if="c.an_detail_metatype == 1">
                {{ c.an_detail_content }}
              </div>
              <p
                v-html="c.an_detail_content"
                v-else-if="c.an_detail_metatype == 2"
              ></p>
              <img :src="c.an_detail_metatype" alt="" v-else />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Question_3",
  props: {
    is_num: Number, //对应题数
    data: Object, //问题数据
    type: Number, //1答题   2 试卷
    isReveal: Boolean, //答案解析状态
  },
  data() {
    return {
      check_all: [],
      paper: [], //答案
    };
  },
  mounted() {},
  computed: {},
  created() {
    console.log(this.type);
    if (this.type == 2) {
      this.paper = this.$store.state.paper;
    }
  },
  methods: {
    //配伍 材料 选择 2
    pw_click(val, i) {
      switch (this.type) {
        case 1:
          if (this.data.qu_list[i].option_type == 4) {
            if (!this.data.qu_list[i].is_y) {
              if (this.data.qu_list[i].user_answer == "") {
                this.data.qu_list[i].user_answer = [...val];
              } else {
                let index = this.data.qu_list[i].user_answer.indexOf(val);
                if (index > -1) {
                  this.data.qu_list[i].user_answer.splice(index, 1);
                } else {
                  this.data.qu_list[i].user_answer.push(val);
                }
              }
            }
          } else {
            this.data.qu_list[i].is_y = true;
            if (this.data.qu_list[i].user_answer == "") {
              this.data.qu_list[i].user_answer = val;
              if (
                this.data.qu_list[i].user_answer == this.data.qu_list[i].answer
              ) {
                this.data.qu_list[i].is_YN = 1;
              } else {
                this.data.qu_list[i].is_YN = 2;
              }
              let a = this.data.qu_list.find((el) => {
                return el.user_answer == "";
              });
              this.is_2_show = a ? false : true;
            }
          }
          break;
        case 2:
          let paper = this.paper;
          if (this.data.qu_list[i].option_type == 4) {
            let a = paper[this.data.qu_list[i].isNum + 1];
            if (a) {
              a = a.split(",");
              if (a.indexOf(val) > -1) {
                a.splice(a.indexOf(val), 1);
                paper[this.data.qu_list[i].isNum + 1] = a.toString();
              } else {
                a = [...a, ...val].sort();
                paper[this.data.qu_list[i].isNum + 1] = a.toString();
              }
            } else {
              a = [...val].sort();
              paper[this.data.qu_list[i].isNum + 1] = a.toString();
            }
          } else {
            paper[this.data.qu_list[i].isNum + 1] = val;
          }
          console.log(paper);
          this.$store.commit("PaperState", paper);
          this.paper = paper;
          this.$emit("isTNum", this.data.qu_list[i].isNum + 1);
          this.$forceUpdate();
          break;
      }
    },
    //多选确认
    check_click(i) {
      let b = this.data.qu_list[i].user_answer.filter((e) => {
        return e != "";
      });
      let a = b.sort();
      this.data.qu_list[i].user_answer = a.toString();
      this.data.qu_list[i].is_y = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.d_x_x_z {
  background: #3e7eff !important;
  color: #fff !important;
  border: none !important;
}
.option_name p {
  font-weight: 400 !important;
}
.radio_box_pcl{
  background: #fff !important;
}
</style>
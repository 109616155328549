<template>
  <div>
    <!-- 题目卡片 -->
    <!-- v-if="index === page" -->
    <!-- 题目 / qu_metatype 1纯文字 2富文本 3图片链接-->
    <!-- 单选题 -->
    <div class="topic_box">
      <!-- 单选题 -->
      <div :id="type == 2 ? data.isNum + 1 : is_num">
        <div class="question_title" v-show="data.qu_metatype == 1">
          {{ type == 2 ? data.isNum + 1 : is_num }}. {{ data.qu_content }}
        </div>
        <div
          class="question_title"
          v-show="data.qu_metatype == 2"
          style="display: flex"
        >
          {{ type == 2 ? data.isNum + 1 : is_num }}.
          <p style="max-width: 1050px" v-html="data.qu_content"></p>
        </div>
        <div v-show="data.qu_metatype == 3">
          {{ type == 2 ? data.isNum + 1 : is_num }}.&nbsp;
          <img style="max-width: 1050px" :src="data.qu_content" alt="" />
        </div>
      </div>
    </div>
    <!-- 单选问题选项 -->
    <div class="radio_box" style="margin-top: 10 px">
      <ul>
        <!-- A -->
        <li v-show="data.an_item_a_metatype && data.an_item_a_metatype > 0">
          <div class="left" @click="clickQulist('A')">
            <!-- 回显状态 -->
            <button
              :class="
                type == 2
                  ? paper[data.isNum + 1] &&
                    paper[data.isNum + 1].indexOf('A') > -1
                    ? 'd_x_x_z'
                    : ''
                  : data.user_answer == 'A' && data.user_answer != data.answer
                  ? 'wrong'
                  : data.answer == 'A' && data.user_answer != ''
                  ? 'yes'
                  : ''
              "
            >
              A
            </button>
            <p v-if="data.an_item_a_metatype == 1">
              {{ data.an_item_a_content }}
            </p>
            <p
              v-if="data.an_item_a_metatype == 2"
              v-html="data.an_item_a_content"
            ></p>
            <img
              v-show="data.an_item_a_metatype == 3"
              :src="data.an_item_a_content"
              alt=""
            />
          </div>
          <a
            class="right"
            :href="data.an_item_a_content"
            target="_blank"
            v-show="data.an_item_a_metatype == 3"
          >
            <img src="../../assets/img/yulan.png" alt="" />
            大图预览
          </a>
        </li>
        <!-- B -->
        <li v-show="data.an_item_b_metatype && data.an_item_b_metatype > 0">
          <div class="left" @click="clickQulist('B')">
            <!-- 回显状态 -->
            <button
              :class="
                type == 2
                  ? paper[data.isNum + 1] &&
                    paper[data.isNum + 1].indexOf('B') > -1
                    ? 'd_x_x_z'
                    : ''
                  : data.user_answer == 'B' && data.user_answer != data.answer
                  ? 'wrong'
                  : data.answer == 'B' && data.user_answer != ''
                  ? 'yes'
                  : ''
              "
            >
              B
            </button>
            <p v-if="data.an_item_b_metatype == 1">
              {{ data.an_item_b_content }}
            </p>
            <p
              v-if="data.an_item_b_metatype == 2"
              v-html="data.an_item_b_content"
            ></p>
            <img
              v-show="data.an_item_b_metatype == 3"
              :src="data.an_item_b_content"
              alt=""
            />
          </div>
          <a
            class="right"
            :href="data.an_item_b_content"
            target="_blank"
            v-show="data.an_item_b_metatype == 3"
          >
            <img src="../../assets/img/yulan.png" alt="" />
            大图预览
          </a>
        </li>
        <!-- C -->
        <li v-show="data.an_item_c_metatype && data.an_item_c_metatype > 0">
          <div class="left" @click="clickQulist('C')">
            <!-- 回显状态 -->
            <button
              :class="
                type == 2
                  ? paper[data.isNum + 1] &&
                    paper[data.isNum + 1].indexOf('C') > -1
                    ? 'd_x_x_z'
                    : ''
                  : data.user_answer == 'C' && data.user_answer != data.answer
                  ? 'wrong'
                  : data.answer == 'C' && data.user_answer != ''
                  ? 'yes'
                  : ''
              "
            >
              C
            </button>
            <p v-if="data.an_item_c_metatype == 1">
              {{ data.an_item_c_content }}
            </p>
            <p
              v-if="data.an_item_c_metatype == 2"
              v-html="data.an_item_c_content"
            ></p>
            <img
              v-show="data.an_item_c_metatype == 3"
              :src="data.an_item_c_content"
              alt=""
            />
          </div>
          <a
            class="right"
            :href="data.an_item_c_content"
            target="_blank"
            v-show="data.an_item_c_metatype == 3"
          >
            <img src="../../assets/img/yulan.png" alt="" />
            大图预览
          </a>
        </li>
        <!-- D -->
        <li v-show="data.an_item_d_metatype && data.an_item_d_metatype > 0">
          <div class="left" @click="clickQulist('D')">
            <!-- 回显状态 -->
            <button
              :class="
                type == 2
                  ? paper[data.isNum + 1] &&
                    paper[data.isNum + 1].indexOf('D') > -1
                    ? 'd_x_x_z'
                    : ''
                  : data.user_answer == 'D' && data.user_answer != data.answer
                  ? 'wrong'
                  : data.answer == 'D' && data.user_answer != ''
                  ? 'yes'
                  : ''
              "
            >
              D
            </button>
            <p v-if="data.an_item_d_metatype == 1">
              {{ data.an_item_d_content }}
            </p>
            <p
              v-if="data.an_item_d_metatype == 2"
              v-html="data.an_item_d_content"
            ></p>
            <img
              v-show="data.an_item_d_metatype == 3"
              :src="data.an_item_d_content"
              alt=""
            />
          </div>
          <a
            class="right"
            :href="data.an_item_d_content"
            target="_blank"
            v-show="data.an_item_d_metatype == 3"
          >
            <img src="../../assets/img/yulan.png" alt="" />
            大图预览
          </a>
        </li>
        <!-- E -->
        <li v-show="data.an_item_e_metatype && data.an_item_e_metatype > 0">
          <div class="left" @click="clickQulist('E')">
            <!-- 回显状态 -->
            <button
              :class="
                type == 2
                  ? paper[data.isNum + 1] &&
                    paper[data.isNum + 1].indexOf('E') > -1
                    ? 'd_x_x_z'
                    : ''
                  : data.user_answer == 'E' && data.user_answer != data.answer
                  ? 'wrong'
                  : data.answer == 'E' && data.user_answer != ''
                  ? 'yes'
                  : ''
              "
            >
              E
            </button>
            <p v-if="data.an_item_e_metatype == 1">
              {{ data.an_item_e_content }}
            </p>
            <p
              v-if="data.an_item_e_metatype == 2"
              v-html="data.an_item_e_content"
            ></p>
            <img
              v-show="data.an_item_e_metatype == 3"
              :src="data.an_item_e_content"
              alt=""
            />
          </div>
          <a
            class="right"
            :href="data.an_item_e_content"
            target="_blank"
            v-show="data.an_item_e_metatype == 3"
          >
            <img src="../../assets/img/yulan.png" alt="" />
            大图预览
          </a>
        </li>
        <!-- F -->
        <li v-show="data.an_item_f_metatype && data.an_item_f_metatype > 0">
          <div class="left" @click="clickQulist('F')">
            <!-- 回显状态 -->
            <button
              :class="
                type == 2
                  ? paper[data.isNum + 1] &&
                    paper[data.isNum + 1].indexOf('F') > -1
                    ? 'd_x_x_z'
                    : ''
                  : data.user_answer == 'F' && data.user_answer != data.answer
                  ? 'wrong'
                  : data.answer == 'F' && data.user_answer != ''
                  ? 'yes'
                  : ''
              "
            >
              F
            </button>
            <p v-if="data.an_item_f_metatype == 1">
              {{ data.an_item_f_content }}
            </p>
            <p
              v-if="data.an_item_f_metatype == 2"
              v-html="data.an_item_f_content"
            ></p>
            <img
              v-show="data.an_item_f_metatype == 3"
              :src="data.an_item_f_content"
              alt=""
            />
          </div>
          <a
            class="right"
            :href="data.an_item_f_content"
            target="_blank"
            v-show="data.an_item_f_metatype == 3"
          >
            <img src="../../assets/img/yulan.png" alt="" />
            大图预览
          </a>
        </li>
      </ul>
    </div>

    <!-- 答案解析------------------------------------------------- -->
    <div class="analysis_box" v-if="data.user_answer != '' && type != 2">
      <div class="top">
        <div class="left">
          <span>正确答案: {{ data.answer }}</span>
          <span
            v-show="data.answer == data.user_answer && data.user_answer != ''"
          >
            您的答案:&nbsp;
            <p style="margin-right: 20px">
              {{ data.user_answer }}
            </p>
            <img
              class="dui_icon"
              src="../../assets/img/dui_answer.png"
              alt=""
            />
          </span>
          <span
            class="wrong_answer"
            v-show="data.answer != data.user_answer || data.user_answer == ''"
          >
            您的答案:&nbsp;
            <p style="margin-right: 20px">
              {{ data.user_answer }}
            </p>
            <img src="../../assets/img/wrong.png" alt="" />
          </span>
        </div>
      </div>
      <div class="down" v-show="!isReveal">
        <div class="down_content">
          <!-- 答案解析的元数据类型，1纯文字，2富文本(HTML)，3纯图片 -->
          <div>答案解析:</div>
          <div v-if="data.an_detail_metatype == 1">
            {{ data.an_detail_content }}
          </div>
          <p
            v-html="data.an_detail_content"
            v-else-if="data.an_detail_metatype == 2"
          ></p>
          <img :src="data.an_detail_metatype" alt="" v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Question_1",
  props: {
    is_num: Number, //对应题数
    data: Object, //问题数据
    type: Number, //1答题   2 试卷
    isReveal: Boolean, //答案解析状态
  },
  data() {
    return {
      paper: [], //答案
    };
  },
  watch: {},
  computed: {},
  created() {
    if (this.type == 2) {
      this.paper = this.$store.state.paper;
    }
  },
  mounted() {},
  methods: {
    //选择
    clickQulist(val) {
      switch (this.type) {
        case 1:
          if (this.data.user_answer == "") {
            this.data.user_answer = val;
            if (this.data.user_answer == this.data.answer) {
              this.data.is_YN = 1;
            } else {
              this.data.is_YN = 2;
            }
          }
          break;
        case 2:
          //   this.$emit("pick", { index: this.data.isNum + 1, answer: val });
          let paper = this.paper;
          paper[this.data.isNum + 1] = val;
          this.$store.commit("PaperState", paper);
          this.paper = paper;
          this.$emit("isTNum", this.data.isNum + 1);
          this.$forceUpdate();
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.d_x_x_z {
  background: #3e7eff !important;
  color: #fff !important;
  border: none !important;
}
</style>